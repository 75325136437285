<template>
  <v-card>
    <v-card-title>
      List Encounters
      <v-spacer></v-spacer>
      <!-- clear search/filters -->
      <btn
        label="Reset Search/Filters"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetSearchFilters"
      ></btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="usersEncounters"
      item-key="id"
      :expanded.sync="expanded"
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      :sort-by.sync="sort.by"
      :sort-desc.sync="sort.desc"
      hide-default-footer
      show-expand
      single-expand
      class="has-pagination"
      @page-count="pagination.pageCount = $event"
      @click:row="viewEncounter"
    >
      <template #top>
        <v-row class="mx-4 mt-2 pb-1">
          <!-- first name -->
          <text-field
            v-model="search.first_name"
            :append-icon="icons.mdiMagnify"
            label="First Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- last name -->
          <text-field
            v-model="search.last_name"
            :append-icon="icons.mdiMagnify"
            label="Last Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- date of birth -->
          <date-input
            v-model="search.dob_us"
            :append-icon="icons.mdiMagnify"
            label="Date of Birth"
            class="col-sm-4 px-1"
            rules="dob"
          ></date-input>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- facility filter -->
          <select-box
            v-model="search.facility"
            :items="facilities"
            label="Facility"
            class="col-sm-6 px-1"
          ></select-box>
          <!-- gender filter -->
          <select-box
            v-model="search.gender"
            :items="genders"
            label="Gender"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- last 4 of ssn -->
          <text-field
            v-model="search.ssn_last_4"
            :append-icon="icons.mdiMagnify"
            label="SSN"
            mask="####"
            placeholder="####"
            class="col-sm-3 px-1"
          ></text-field>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- visit date last 14 days -->
          <checkbox
            v-model="search.last_fourteen_days"
            class="col-sm-1p25 px-0"
            label="Last 14"
            hide-details
            stack
            @click="if (search.last_fourteen_days) search.visit_date = null"
          ></checkbox>
          <!-- visit date -->
          <date-picker
            v-model="search.visit_date"
            label="Visit Date"
            class="col-sm-3p25 px-1"
            clearable
            @input="if (search.visit_date) search.last_fourteen_days = false"
          ></date-picker>
          <!-- visit type filter -->
          <select-box
            v-model="search.visit_type"
            :items="visitTypes"
            label="Type of Visit"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- signed? -->
          <select-box
            v-model="search.signed"
            :items="query"
            label="Signed"
            class="col-sm-2p25 px-1"
          ></select-box>
          <!-- synced? -->
          <select-box
            v-model="search.synced"
            :items="query"
            label="Synced"
            class="col-sm-2p25 px-1"
          ></select-box>
        </v-row>

        <!-- icon help -->
        <v-expand-transition>
          <v-row
            v-show="showHelp"
            class="help-icons"
          >
            <div class="secondary--text">
              Icon Help:
            </div>
            <icon-value
              :icon="icons.mdiChevronDown"
              color="secondary"
            >
              Expand
            </icon-value>
            <icon-value
              :icon="icons.mdiDecagramOutline"
              color="secondary"
            >
              Validated
            </icon-value>
            <icon-value
              :icon="icons.mdiClipboardOutline"
              color="secondary"
            >
              Signed
            </icon-value>
            <icon-value
              :icon="icons.mdiCloudOutline"
              color="secondary"
            >
              Synced
            </icon-value>
            <icon-value
              :icon="icons.mdiDotsVertical"
              color="secondary"
            >
              Action Menu
            </icon-value>
            <v-icon @click="showHelp = !showHelp">
              {{ icons.mdiClose }}
            </v-icon>
          </v-row>
        </v-expand-transition>
      </template>

      <!-- header overrides -->
      <template #header.is_validated>
        <v-icon>{{ icons.mdiDecagramOutline }}</v-icon>
      </template>
      <template #header.is_signed>
        <v-icon>{{ icons.mdiClipboardOutline }}</v-icon>
      </template>
      <template #header.is_synced>
        <v-icon>{{ icons.mdiCloudOutline }}</v-icon>
      </template>
      <template #header.actions>
        <v-icon
          color="warning"
          @click="showHelp = !showHelp"
        >
          {{ icons.mdiInformationOutline }}
        </v-icon>
      </template>

      <!-- visit date -->
      <template #item.visit_date="{ item }">
        {{ $date(item.visit_date).format('MM/DD/YYYY') }}
      </template>

      <!-- first name -->
      <template #item.first_name="{ item }">
        <div class="table-no-wrap">
          {{ item.first_name }}
        </div>
      </template>

      <!-- last name -->
      <template #item.last_name="{ item }">
        <div class="table-no-wrap">
          {{ item.last_name }}
        </div>
      </template>

      <!-- facility -->
      <template #item.place_of_service_id="{ item }">
        <div class="table-no-wrap">
          {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
        </div>
      </template>

      <!-- visit type -->
      <template #item.visit_type="{ item }">
        <span
          v-if="item.visit_type"
          class="visit-type"
          :class="item.visit_type.toLowerCase()"
        >
          {{ item.visit_type === 'Follow-up' ? 'F/U' : item.visit_type }}
        </span>
      </template>

      <!-- Validated -->
      <template #item.is_validated="{ item }">
        <v-icon
          :color="item.is_validated || item.is_signed ? 'success' : 'error'"
        >
          {{ item.is_validated || item.is_signed ? icons.mdiCheckDecagram : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- Signed -->
      <template #item.is_signed="{ item }">
        <v-icon
          :color="item.is_signed ? 'success' : 'error'"
        >
          {{ item.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- Synced -->
      <template #item.is_synced="{ item }">
        <v-icon
          :color="item.is_synced ? 'success' : 'error'"
        >
          {{ item.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- actions -->
      <template #item.actions="{item}">
        <v-menu
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="viewEncounter(item)">
              <icon-value :icon="icons.mdiAccountEye">
                View/Edit Encounter
              </icon-value>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              :disabled="item.is_deceased"
              @click="createEncounter(item)"
            >
              <icon-value
                :disabled="item.is_deceased"
                :icon="icons.mdiAccountMultiplePlus"
              >
                Create New Encounter
              </icon-value>
            </v-list-item>

            <v-list-item @click="editPatient(item)">
              <icon-value :icon="icons.mdiAccountEdit">
                Edit This Patient
              </icon-value>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- expanded data -->
      <template #expanded-item="{ item }">
        <!--
          The getPatient() method can be CPU intense, and was being accessed many times
          in the section below. So this for "loop" is a trick to only run the getPatient()
          once per encounter. There's only one item in the array, so it only renders once,
          but it allows the use of the "patient" value to be used without overhead.
        -->
        <td
          v-for="(patient) in [getPatient(item.patient_id)]"
          :key="patient.id"
          :colspan="headers.length"
        >
          <div>
            <label>Practice Type:</label>
            {{ $store.getters['baseData/practiceTypeFromId'](item.practice_type_id).title }}
          </div>
          <div>
            <label>Facility:</label>
            {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
          </div>
          <div>
            <label>Patient Name:</label>
            <patient-name :patient="patient"></patient-name>
          </div>
          <div>
            <label>Gender:</label>
            {{ patient.gender }}
          </div>
          <div
            v-if="typeof patient.date_deceased !== 'undefined' && patient.is_deceased"
          >
            <label>Deceased Date:</label>
            {{ $date(patient.date_deceased).format('MM/DD/YYYY') }}
          </div>
          <div v-else>
            <label>Date of Birth:</label>
            {{ $date(patient.dob).format('MM/DD/YYYY') }} ({{ $custom.getAgeDescription(patient.dob) }})
          </div>
          <div>
            <label>SSN:</label>
            {{ patient.ssn_last_4 }}
          </div>
          <div>
            <label>Fax Date:</label>
            {{ item.fax_date ? $date(item.fax_date).format('MM/DD/YYYY') : '' }}
          </div>
          <div v-if="item.is_quick_entry">
            <label>Quick Entry:</label>
            Yes
          </div>
          <div v-if="item.is_deferred_care">
            <label>Deferred Care:</label>
            Yes
          </div>
          <div v-else-if="item.is_signed && item.is_synced && item.estimated_payment">
            <label>Estimated Pay:</label>
            {{ $custom.formatCurrency(item.estimated_payment) }}
          </div>
          <div>
            <label>Encounter ID:</label>
            {{ item.id }}
          </div>
        </td>
      </template>
    </v-data-table>

    <!-- pagination -->
    <pagination
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.itemsPerPage"
      :page-count="pagination.pageCount"
    ></pagination>

    <v-card-actions>
      <v-spacer></v-spacer>
      <btn
        label="Create Encounter"
        :icon="icons.mdiAccountMultiplePlus"
        @click="$router.push({ name: 'encounter-patient-find' })"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable no-param-reassign */

import '@/components/patients/primitives'
import {
  mdiAccountEdit,
  mdiAccountEye,
  mdiAccountMultiplePlus,
  mdiAlertDecagram,
  mdiCheckDecagram,
  mdiChevronDown,
  mdiClipboardCheck,
  mdiClipboardOutline,
  mdiClose,
  mdiCloseCircle, mdiCloudCheckVariant,
  mdiCloudOutline,
  mdiDecagram,
  mdiDecagramOutline,
  mdiDotsVertical,
  mdiFilePlus,
  mdiInformationOutline,
  mdiMagnify,
  mdiSync,
  mdiUndoVariant,
} from '@mdi/js'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

const initialSearch = {
  last_fourteen_days: false,
  visit_date: null,
  visit_date_us: null,
  facility: null,
  visit_type: null,
  signed: null,
  synced: null,
  ssn_last_4: null,
  first_name: null,
  last_name: null,
  gender: null,
  dob: null,
  dob_us: null,
}

const initialState = {
  by: 'visit_date',
  desc: true,
}

export default {
  data() {
    return {
      search: { ...initialSearch },
      sort: { ...initialState },
      userId: this.$authUser.userId(),
      icons: {
        mdiAccountMultiplePlus,
        mdiClipboardCheck,
        mdiCloseCircle,
        mdiCloudCheckVariant,
        mdiMagnify,
        mdiFilePlus,
        mdiUndoVariant,
        mdiSync,
        mdiCheckDecagram,
        mdiAlertDecagram,
        mdiDecagram,
        mdiDotsVertical,
        mdiClose,
        mdiAccountEdit,
        mdiAccountEye,
        mdiDecagramOutline,
        mdiClipboardOutline,
        mdiCloudOutline,
        mdiInformationOutline,
        mdiChevronDown,
      },
      showHelp: false,
      headers: [
        {
          text: 'First',
          value: 'first_name',
          align: 'name',
          filter: value => {
            const valueToCheck = value || '' // default to an empty string if value is falsy.

            return !this.search.first_name || valueToCheck.toLowerCase().indexOf(this.search.first_name.trim().toLowerCase()) === 0
          },
        },
        {
          text: 'Last',
          value: 'last_name',
          align: 'name',
          filter: value => {
            const valueToCheck = value || '' // default to an empty string if value is falsy.

            return !this.search.last_name || valueToCheck.toLowerCase().indexOf(this.search.last_name.trim().toLowerCase()) === 0
          },
        },
        {
          text: 'Visit Date',
          value: 'visit_date',
          filter: value => (!this.search.visit_date && !this.search.last_fourteen_days)
            || (!this.search.last_fourteen_days && value === this.search.visit_date)
            || (this.search.last_fourteen_days && dayjs().diff(value, 'days') <= 14),
        },
        {
          text: 'Facility',
          value: 'place_of_service_id',
          align: 'facility',
          filter: value => !this.search.facility || value === this.search.facility,
        },
        {
          text: 'Type',
          value: 'visit_type',
          align: 'xsmall',
          filter: value => !this.search.visit_type || value === this.search.visit_type,
        },
        {
          text: 'Validated',
          value: 'is_validated',
          align: 'xsmall',
        },
        {
          text: 'Signed',
          value: 'is_signed',
          align: 'xsmall',
          filter: value => this.search.signed === null || value === this.search.signed,
        },
        {
          text: 'Synced',
          value: 'is_synced',
          align: 'xsmall',
          filter: value => this.search.synced === null || value === this.search.synced,
        },
        {
          value: 'dob',
          align: 'hide',
          filter: value => !this.search.dob || value === this.search.dob || this.search.dob.length < 10,
        },
        {
          value: 'gender',
          align: 'hide',
          filter: value => !this.search.gender || value === this.search.gender,
        },
        {
          value: 'ssn_last_4',
          align: 'hide',
          filter: value => !this.search.ssn_last_4 || value === this.search.ssn_last_4 || this.search.ssn_last_4.length < 4,
        },
        {
          text: '', value: 'actions', align: 'xsmall', sortable: false,
        },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      debounceTimer: null,
    }
  },
  beforeMount() {
    this.$store.dispatch('loading', false)
  },
  beforeDestroy() {
    // Clear debounceTimer to prevent memory leaks
    clearTimeout(this.debounceTimer)
    this.debounceTimer = null
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('encounters', ['isSynced', 'genders', 'visitTypes']),

    // ...mapState('encounters', { encounters: 'items' }),
    // ...mapState('patients', { patients: 'items' }),
    usersEncounters() {
      const filteredEncounters = this.$store.state.encounters.items.filter(
        x => x.created_by_user_id === this.userId
          && x.deleted !== true,
      )
      filteredEncounters
        .forEach(encounter => {
          const patient = this.getPatient(encounter.patient_id)
          encounter.first_name = patient.first_name
          encounter.last_name = patient.last_name
          encounter.gender = patient.gender
          encounter.ssn_last_4 = patient.ssn_last_4
          encounter.dob = patient.dob
          encounter.is_deceased = patient.is_deceased
        })

      return filteredEncounters
    },
  },
  watch: {
    'search.visit_date': {
      handler() {
        this.search.visit_date_us = this.search.visit_date !== null ? this.$date(this.search.visit_date).format('MM/DD/YYYY') : null
      },
    },
    'search.dob_us': {
      handler() {
        this.search.dob = this.search.dob_us ? this.$date(this.search.dob_us).format('YYYY-MM-DD') : null
      },
    },
    search: {
      deep: true,
      handler() { this.updateQuery() },
    },
    pagination: {
      deep: true,
      handler() { this.updateQuery() },
    },
    sort: {
      deep: true,
      handler() { this.updateQuery() },
    },
  },
  mounted() {
    // Query string sets search filters & pagination
    if (!this.$custom.isObjectEmpty(this.$route.query)) {
      this.search = {
        ...initialSearch,
        ...this.$route.query,
        facility: Number(this.$route.query.facility),
        signed: this.$custom.strToBoolNull(this.$route.query.signed),
        synced: this.$custom.strToBoolNull(this.$route.query.synced),
        last_fourteen_days: this.$custom.strToBoolNull(this.$route.query.last_fourteen_days),
      }
      this.sort.by = this.$route.query.by
      this.sort.desc = this.$custom.strToBoolNull(this.$route.query.desc)
      if (this.$route.query.itemsPerPage) {
        this.pagination.itemsPerPage = Number(this.$route.query.itemsPerPage)
      }
      if (this.$route.query.page) {
        this.$nextTick(() => {
          this.pagination.page = Number(this.$route.query.page)
        })
      }
    }
  },
  methods: {
    updateQuery() {
      // Debounce
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        // Replace the query
        const query = { ...this.search, ...this.sort, ...this.pagination }
        Object.keys(query).forEach(key => { if (query[key] === null || typeof query[key] === 'undefined') query[key] = '' })
        window.history.replaceState({}, null, `${this.$route.path}?${new URLSearchParams(query).toString()}`)
        this.$store.commit('route/updateQuery', query)
      }, 250)
    },
    resetSearchFilters() {
      this.search = { ...initialSearch }
      this.sort = { ...initialState }
      this.showHelp = false
    },
    createEncounter(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.patient_id)
      this.$store.dispatch('encounters/loadEncounterData', item.patient_id)

      this.$router.push({ name: 'create-encounter', query: { id: item.patient_id }, params: { practiceTypeId: item.practice_type_id } })
    },
    editPatient(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.patient_id)
      this.$store.dispatch('encounters/loadEncounterData', item.patient_id)

      this.$router.push({ name: 'edit-patient', query: { id: item.patient_id } })
    },
    viewEncounter(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.patient_id)
      this.$store.dispatch('encounters/loadEncounterData', item.patient_id)

      if ((item.is_signed && item.is_synced) || item.is_deferred_care) {
        // View signed surgical note
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).noteRoute,
          query: { id: item.id },
        })
      } else if (item.created_by_user_id !== this.userId) {
        // Don't allow a different provider to edit an encounter
        this.$store.dispatch('notify', { value: 'You can\'t edit this encounter as you\'re not the provider who created it.', color: 'error' })
      } else {
        // Edit encounter note
        this.$store.dispatch('loading', true)
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).encounterRoute,
          query: { id: item.id },
        })
      }
    },
    getPatient(patientId) {
      return this.$store.getters['patients/getById'](patientId)
    },
  },
}
</script>
